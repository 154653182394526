<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :width="width"
      center
      destroy-on-close
    >
      <BaseForm ref="myform" v-on="$listeners" v-bind="formConfig">
        <template v-for="slot in slotArr" v-slot:[slot]>
          <slot :name="slot"></slot>
        </template>
      </BaseForm>
      <div class="footer fr f-ac-jc">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BaseForm from "@/components/BaseForm/index";
export default {
  name: "BaseModal",
  //组件注册
  components: { BaseForm },
  props: {
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: () => "30%",
    },
    formConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  //监视属性
  watch: {},
  //计算属性
  computed: {
    slotArr() {
      const slotList = [];
      this.formConfig.form.forEach((item) => {
        if (item.type === "slot" || item.type === "oslot") {
          slotList.push(item.field);
        } else if (item.appendSlot) {
          slotList.push(item.appendSlot);
        }
      });
      return slotList;
    },
  },
  //数据
  data() {
    return {
      dialogVisible: false,
    };
  },
  //方法
  methods: {
    /*******
     * @ description: 初始化表单
     * @ param {*} data
     * @ return {*}
     ******/
    init(data) {
      // 避免不传参数时undefined报错
      this.dialogVisible = true;
      if (data) {
        this.update(data);
      }
      // 解包回显参数
    },

    /*******
     * @ description: 更新数据
     * @ return {*}
     ******/
    update(data) {
      this.$nextTick(
        () => this.$refs.myform && this.$refs.myform.updateData(data) // 为了解决表单回显数据时，表单数据未渲染完成的问题
      );
    },
    async validateField(field) {
      const res = await this.$refs.myform.validateField(field);
      return res;
    },

    /*******
     * @ description: 提交表单数据
     * @ return {*}
     ******/
    async submit() {
      return await this.$refs["myform"].submit();
    },
    /*******
     * @ description:关闭弹窗
     * @ return {*}
     ******/
    close() {
      this.dialogVisible = false;
      this.$refs["myform"].reset();
    },
  },
};
</script>
<style scoped lang="scss"></style>
