import request from "@/utils/request";

export function login(data) {
  return request({
    url: "/admin2/login/login",
    method: "post",
    data,
  });
}
// 填写/修改乘车信息（k可批量）
export function send_car_info(data) {
  return request({
    url: "/admin2/baomingc/send_car_info",
    method: "post",
    data,
  });
}

// 通知乘车信息（k可批量）
export function send_car_info_msg(data) {
  return request({
    url: "/admin2/baomingc/send_car_info_msg",
    method: "post",
    data,
  });
}

// 通知乘车信息（可批量）
export function get_qrcode(data) {
  return request({
    url: "/admin2/baomingc/get_qrcode",
    method: "post",
    data,
  });
}

// 获取公司列表
export function get_companyList(data) {
  return request({
    url: "/admin2/companyc/getList",
    method: "get",
    params: data,
  });
}

// 获取职位列表
export function job_list(data) {
  return request({
    url: "/admin2/vacation_jobc/getList",
    method: "get",
    params: data,
  });
}

// 获取职位信息
export function job_info(data) {
  return request({
    url: "/admin2/vacation_jobc/info",
    method: "get",
    params: data,
  });
}

// 安排面试（可批量）
export function send_interview_info(data) {
  return request({
    url: "/admin2/baomingc/send_interview_info",
    method: "post",
    data,
  });
}

// 调整职位（可批量）
export function change_job(data) {
  return request({
    url: "/admin2/baomingc/change_job",
    method: "post",
    data,
  });
}

// 获取招工配置
export function job_conf(data) {
  return request({
    url: "/admin2/systemc/job_conf",
    method: "get",
    params: data,
  });
}

// 保存招工配置
export function job_conf_save(data) {
  return request({
    url: "/admin2/systemc/save",
    method: "post",
    data,
  });
}

// 填写/修改入职信息（k可批量）
export function set_entry(data) {
  return request({
    url: "/admin2/baomingc/set_entry",
    method: "post",
    data,
  });
}

// 通知入职信息（可批量）
export function send_entry(data) {
  return request({
    url: "/admin2/baomingc/send_entry",
    method: "post",
    data,
  });
}

// 获取资金记录列表
export function getMoneyList(data) {
  return request({
    url: "/admin2/baomingc/getMoneyList",
    method: "get",
    params: data
  });
}

// 提现审核
export function check_cash(data) {
  return request({
    url: "/admin2/baomingc/check_cash",
    method: "post",
    data
  });
}

// 预支工资二审审核
export function check_two_borrow(data) {
  return request({
    url: "/admin2/baomingc/check_two_borrow",
    method: "post",
    data
  });
}


// 发工资
export function save_job_money(data) {
  return request({
    url: "/admin2/baomingc/save_job_money",
    method: "post",
    data
  });
}

// 获取关键字列表
export function temp_msg_keywordsc_list(data) {
  return request({
    url: "/admin2/temp_msg_keywordsc/get_list",
    method: "get",
    params: data,
  });
}

// 新增关键字
export function temp_msg_keywordsc_save(data) {
  return request({
    url: "/admin2/temp_msg_keywordsc/save",
    method: "post",
    data
  });
}

// 删除关键字
export function temp_msg_keywordsc_del(data) {
  return request({
    url: "/admin2/temp_msg_keywordsc/del",
    method: "post",
    data
  });
}

// 设置面试结果
export function set_interview_status(data) {
  return request({
    url: "/admin2/baomingc/set_interview_status",
    method: "post",
    data
  });
}

// 离职操作
export function depart_api(data) {
  return request({
    url: "/admin2/baomingc/depart",
    method: "post",
    data
  });
}