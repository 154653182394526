
import axios from 'axios'
import {
	Notification,
	MessageBox,
	Message
} from 'element-ui'
import router from '@/router';
import {
	getToken,
  removeToken
} from '@/utils/auth'
import errorCode from '@/utils/errorCode'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	timeout: 20000
})
/** 添加请求拦截器 **/
service.interceptors.request.use(
	config => {
		if (getToken()) {
			config.headers['token'] = getToken()
			// config.headers['token'] = 'McTxIpwhOpjjAh6fMjTjckwuNyzc2Mjc4OToxOTEO0O0O'
		}
		// if (config.method == 'post') {
		// 	config.headers['Content-Type'] = 'application/json;charset=utf-8'
		// }
		if (config.data instanceof FormData) {
			config.headers = {
				'Content-Type': 'multipart/form-data',
				'token': getToken()
				// 'token': 'McTxIpwhOpjjAh6fMjTjckwuNyzc2Mjc4OToxOTEO0O0O'
			}
		}
		return config
	},
	error => {
		console.log(error)
		return Promise.reject(error)
	}
)

// 这里的conut 是为了防止tokne失效导致太多提示框弹出
let count = 0
/** 添加响应拦截器  **/
service.interceptors.response.use(
	response => {
		const res = response.data;
		if (res.code != 0) {
			if (res.code == -101) {
				if (count === 0) {
					Message({
						message: "身份验证过期，请重新登录！",
						type: "error",
					});
				}
				localStorage.removeItem("token")
        removeToken()
				router.push({ path: '/login', query: { path: router.currentRoute.fullPath } })
			} else {
				Message({
					message: res.msg,
					type: "error",
				});
			}

			// else if (res.code == 10001) {
			//     Message.error(res.msg);
			// }
			count++
			return Promise.reject(res)

		} else {
			return res
		}

	},
	error => {
		if (count === 0) {
			if (error.response.data.code == -101) {
				Message({
					message: '登录令牌失效，请重新登录',
					type: 'error',
					duration: 3 * 1000
				})
				count = 0;
        removeToken()
				localStorage.removeItem("token")
				router.push({ path: '/login', query: { path: router.currentRoute.fullPath } })
			} else {
				Message({
					message: error.message,
					type: 'error',
					duration: 5 * 1000
				})
			}

		}
		count++
		return Promise.reject(error)
	}
)

export default service
