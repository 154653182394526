<template>
  <el-form :label-width="labelWidth" ref="form" :model="formData">
    <el-row>
      <template v-for="(item, index) in form">
        <el-col
          v-bind="item.colLayout ? item.colLayout : colLayout"
          :key="index"
        >
          <!-- 非表单子项的slot -->
          <template v-if="item.type === 'oslot'">
            <slot :name="item.field"></slot>
          </template>
          <!-- 表单子项的slot -->
          <template v-else>
            <el-form-item
              v-if="!item.isHidden"
              v-bind="item.formItem"
              :prop="item.field"
              :style="item.formItem.style ? item.formItem.style : itemStyle"
            >
              <template v-if="item.type === 'input'">
                <div v-bind="item.divNode ? item.divNode : divNode">
                  <el-input
                    v-model="formData[`${item.field}`]"
                    v-bind="item.fieldItem"
                    :disabled="disabled || item.disabled"
                    v-on="functionDict[item.field]"
                  >
                    <template v-if="item.append" slot="append">{{
                      item.append
                    }}</template>
                    <template v-else-if="item.appendSlot" slot="append">
                      <slot :name="item.appendSlot"></slot
                    ></template>
                  </el-input>
                </div>
              </template>
              <template v-else-if="item.type === 'select'">
                <div v-bind="item.divNode ? item.divNode : divNode">
                  <el-select
                    v-model="formData[`${item.field}`]"
                    v-bind="item.fieldItem"
                    :disabled="disabled || item.disabled"
                    @change="handleSelect(item.field, $event)"
                    v-on="functionDict[item.field]"
                  >
                    <el-option
                      v-for="option in item.options"
                      :key="option.value"
                      :value="option.value"
                      :label="option.label"
                    ></el-option>
                  </el-select>
                </div>
              </template>
              <template v-else-if="item.type === 'datepicker'">
                <div v-bind="item.divNode ? item.divNode : divNode">
                  <el-date-picker
                    v-model="formData[`${item.field}`]"
                    v-bind="item.fieldItem"
                    :disabled="disabled || item.disabled"
                    v-on="functionDict[item.field]"
                  >
                  </el-date-picker>
                </div>
              </template>
              <template v-else-if="item.type === 'timepicker'">
                <div v-bind="item.divNode ? item.divNode : divNode">
                  <el-time-picker
                    v-model="formData[`${item.field}`]"
                    v-bind="item.fieldItem"
                    :disabled="disabled || item.disabled"
                    v-on="functionDict[item.field]"
                  >
                  </el-time-picker>
                </div>
              </template>
              <template v-else-if="item.type === 'radio'">
                <div v-bind="item.divNode ? item.divNode : divNode">
                  <el-radio-group
                    v-model="formData[`${item.field}`]"
                    v-bind="item.fieldItem"
                    :disabled="disabled || item.disabled"
                    @input="handleRadio(item.field, $event)"
                    v-on="functionDict[item.field]"
                  >
                    <el-radio
                      v-for="option in item.options"
                      :label="option.value"
                      :key="option.value"
                    >
                      {{ option.label }}</el-radio
                    >
                  </el-radio-group>
                </div>
              </template>
              <template v-else-if="item.type === 'checkbox'">
                <div v-bind="item.divNode ? item.divNode : divNode">
                  <el-checkbox
                    v-model="formData[`${item.field}`]"
                    :disabled="disabled || item.disabled"
                    v-on="functionDict[item.field]"
                    >{{ item.fieldItem.title }}</el-checkbox
                  >
                </div>
              </template>
              <template v-else-if="item.type === 'checkbox-group'">
                <div v-bind="item.divNode ? item.divNode : divNode">
                  <el-checkbox-group
                    v-model="formData[`${item.field}`]"
                    v-on="functionDict[item.field]"
                  >
                    <el-checkbox
                      :disabled="disabled || item.disabled"
                      v-for="checkItem in item.options"
                      :key="checkItem.value"
                      :label="checkItem.value"
                      >{{ checkItem.label }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </template>
              <template v-else-if="item.type === 'input-number'">
                <div v-bind="item.divNode ? item.divNode : divNode">
                  <el-input-number
                    v-model="formData[`${item.field}`]"
                    v-on="functionDict[item.field]"
                    v-bind="item.fieldItem"
                  >
                  </el-input-number>
                </div>
              </template>
              <template v-else-if="item.type === 'tree'">
                <div v-bind="item.divNode ? item.divNode : divNode">
                  <el-tree
                    v-model="formData[`${item.field}`]"
                    v-on="functionDict[item.field]"
                    v-bind="item.fieldItem"
                  >
                  </el-tree>
                </div>
              </template>
              <template v-else-if="item.type === 'switch'">
                <div v-bind="item.divNode ? item.divNode : divNode">
                  <el-switch
                    v-model="formData[`${item.field}`]"
                    v-on="functionDict[item.field]"
                    v-bind="item.fieldItem"
                  >
                  </el-switch>
                </div>
              </template>
              <template v-else-if="item.type === 'slot'">
                <slot :name="item.field"></slot>
              </template>
            </el-form-item>
          </template>
        </el-col>
      </template>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: "BaseForm",
  //组件注册
  props: {
    labelWidth: {
      type: String,
      default: () => "80px",
      description: "表单项的全局label宽度",
    },
    form: {
      type: Array,
      default: () => [],
      description: "表单项",
    },
    itemStyle: {
      type: Object,
      default: () => ({ padding: "10px 40px" }),
      description: "表单项的样式",
    },
    colLayout: {
      type: Object,
      default: () => ({ span: 24 }),
      description: "el-col的布局",
    },
    includeKeys: {
      type: Array,
      default: () => ["id"],
      description: "需要包含的表单项",
    },
    divNode: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    cus_field: {
      type: String,
      default: "",
      description: "自定义字段，用来区别表单",
    },
  },
  created() {
    // 初始化表单数据

    this.initial();
  },

  //数据
  data() {
    return {
      formData: {},
      formatters: {}, //格式化器
      reformatters: {}, //反格式化器
      functionDict: {}, //传入的事件
    };
  },
  //方法
  methods: {
    /*******
     * @ description: 校验表单+提交
     * @ return {*}
     ******/
    async submit() {
      let res = await this.$refs["form"].validate();
      if (res) {
        let newObj = this.fetchData();
        this.$emit("submit", newObj);
        return newObj;
      }
    },

    validateField(field) {
      return new Promise((resolve, reject) => {
        this.$refs["form"].validateField(field, (e) => {
          if (!e) {
            let value;
            if (field in this.reformatters) {
              value = this.reformatters[field](this.formData[field]);
            } else {
              value = this.formData[field];
            }
            this.$emit("validateField", { field, value });
            resolve({ field, value });
          } else {
            reject(e);
          }
        });
      });
    },

    /*******
     * @ description: 获取表单校验前数据
     * @ return {*}
     ******/
    fetchData() {
      let newObj = {};
      // 格式化数据
      for (const key in this.formData) {
        if (key in this.reformatters) {
          newObj[key] = this.reformatters[key](
            this.formData[key],
            this.formData
          );
        } else {
          newObj[key] = this.formData[key];
        }
      }
      return newObj;
    },

    /*******
     * @ description: 初始化表单数据
     * @ param {*} field
     * @ return {*}
     ******/
    initial() {
      this.reset();
      this.register();
      this.$nextTick(() => {
        this.clearValidate();
      });
    },

    /*******
     * @ description: 更改表单项后，重新初始化表单
     * @ return {*}
     ******/
    reInitForm(fieldList) {
      for (const key in this.formData) {
        if (!fieldList.includes(key)) {
          delete this.formData[key];
        }
      }
      this.$forceUpdate();
      this.register();
      this.reset(fieldList);
      this.$nextTick(() => {
        this.clearValidate();
      });
    },

    /*******
     * @ description: 注册函数
     * @ return {*}
     ******/
    register() {
      this.regitFunc();
      this.registerFomatter();
    },

    /*******
     * @ description: 注册格式化和反格式化函数
     * @ return {*}
     ******/
    registerFomatter() {
      this.form.forEach((item) => {
        if (item.formatter) {
          this.formatters[item.field] = item.formatter;
        }
        if (item.reformatter) {
          this.reformatters[item.field] = item.reformatter;
        }
      });
    },

    /*******
     * @ description: 清除校验
     * @ return {*}
     ******/
    clearValidate() {
      this.$refs.form.clearValidate();
    },
    /*******
     * @ description: 处理选项变化事件
     * @ param {*} e
     * @ return {*}
     ******/
    handleRadio(field, e) {
      this.$emit("radioChange", { field, value: e });
    },
    handleSelect(field, e) {
      this.$emit("selectChange", { field, value: e });
    },

    /*******
     * @ description: 更新数据
     * @ param {*} data 数据
     * @ param {*} excludeList 不包含的字段
     * @ return {*}
     ******/
    updateData(data, excludeList = []) {
      for (const key in data) {
        if (
          (key in this.formData || this.includeKeys.includes(key)) &&
          !excludeList.includes(key)
        ) {
          if (key in this.formatters) {
            this.$set(
              this.formData,
              key,
              this.formatters[key](data[key], data)
            );
          } else {
            this.$set(this.formData, key, data[key]);
          }
        }
      }
    },
    /*******
     * @ description: 重置数据
     * @ return {*}
     ******/
    reset(fieldList = []) {
      this.form.forEach((item) => {
        //不是控制字段才赋值
        if (!fieldList.includes(item.field)) {
          this.$set(this.formData, item.field, item.default || "");
        }
      });
    },

    /*******
     * @ description: 注册事件函数
     * @ return {*}
     ******/
    regitFunc() {
      this.form.forEach((item) => {
        item.events &&
          item.events.length > 0 &&
          item.events.forEach((func) => {
            if (this.functionDict[item.field]) {
              this.functionDict[item.field][func] = ($event) => {
                this.handleFunc(item.field, $event, func);
              };
            } else {
              this.functionDict[item.field] = {
                [func]: ($event) => {
                  this.handleFunc(item.field, $event, func);
                },
              };
            }
          });
      });
    },

    /*******
     * @ description: 事件回调统一出口
     * @ param {*} field
     * @ param {*} value
     * @ param {*} func
     * @ return {*}
     ******/
    handleFunc(field, value, func) {
      this.$emit(
        "handleFunc",
        {
          field,
          value,
          func,
        },
        this.cus_field
      );
    },
  },
};
</script>
<style scoped lang="scss"></style>
