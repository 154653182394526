<template>
  <div>
    <div class="tips_div">
      <template v-for="(item, index) in car_tips">
        <div
          class="tips_item"
          @click.stop="selectTips(index)"
          :class="{'tips_item_active': item.isSelect }"
          :key="index">
          {{ item.name }}
          <div class="delete-image" v-if="showDel">
            <i class="el-icon-error" color="#000" @click.stop="removeTips(index, item.id)"></i>
          </div>
        </div>
      </template>
      <div
        class="tips_item"
        style="color: #409EFF;border-color: #409EFF;font-size: 24px;user-select:none;"
        @click="showAdd = true">
        +
      </div>
      <div
        class="tips_item"
        style="font-size: 24px;user-select:none;"
        @click="showDel = !showDel">
        -
      </div>
    </div>
    <div class="tips_div" style="align-items: center;font-size: 28px;margin-bottom: 20px;" v-if="showAdd">
      <el-input
        style="width: 260px;"
        type="text"
        v-model="add_car_tips"
        placeholder="输入新的提示语" />
      <div class="tips_item all_center"
        style="color: #409EFF;border: none;margin: 0 10px;font-size: 14px;user-select:none;"
        @click="confirmAdd">
        <i class="el-icon-check" style="font-size: 22px;"></i>
      </div>
      <div class="tips_item all_center" style="margin: 0;border: none;font-size: 14px;user-select:none;"
        @click="showAdd = false">
        <i class="el-icon-close" style="font-size: 22px;"></i>
      </div>
    </div>
    <el-input
      type="text"
      v-model="input_car_tips"
      placeholder="请输入其他提示语" />
  </div>
</template>

<script>
import { temp_msg_keywordsc_list, temp_msg_keywordsc_save, temp_msg_keywordsc_del } from '@/api/common'
export default {
  props: {
    // 获取对应类型下的关键字 （type_id || type_name） 乘车管理
    type: {
      type: [String, Number],
      default: ''
    },
    tips: {
      type: [String, Array],
      default: ''
    }
  },
  watch: {
    type: {
      handler (val) {
        if (!['', null, undefined].includes(val)) {
          this.car_tips = []
          this.input_car_tips = ''
          this.add_car_tips = ''
          this.getList()
        }
      },
      deep: true,
      immediate: true
    },
    
    input_car_tips: {
      handler (val) {
        let carTipsArr = this.car_tips.filter(item => {return item.isSelect})
        let carTips = []
        if (carTipsArr.length) {
          carTips = carTipsArr.map(item => {if (item.isSelect) return item.name})
        }
        this.$emit('tipsChange', { carTips, input: val })
      },
      deep: true,
      immediate: true
    },
    tips: {
      handler (val) {
        if (!['', null, undefined].includes(val)) {
          let tipsArr = val.split('，')
          this.defaultTips = tipsArr
        }
      },
      immediate: true,
      deep: true
    },
  },
  data () {
    return {
      showAdd: false,
      showDel: false,
      car_tips: [],
      defaultTips: [],
      add_car_tips: '',
      input_car_tips: ''
    }
  },
  methods: {
    getList () {
      let params = {
        page: 1,
        limit: 100,
        type: this.type || ''
      }
      temp_msg_keywordsc_list(params)
        .then(res => {
          if (res.code === 0) {
            if (this.car_tips.length) {
              res.data.forEach(item => {
                item.isSelect = false
                this.car_tips.map(it => {
                  if (item.id === it.id) {
                    item.isSelect = it.isSelect
                  }
                })
              })
              this.car_tips = res.data
            } else {
              this.car_tips = res.data.map(item => {
                item.isSelect = false
                return item
              })
            }
            
            this.setDefaultTips()
            
          }
        })
    },
    setDefaultTips () {
      let defaultTips = []
      if (!Array.isArray(this.defaultTips)) {
        defaultTips = this.defaultTips.split(',')
      } else {
        defaultTips = this.defaultTips
      }
      if (this.defaultTips.length && this.car_tips.length) {
        let carTips = this.car_tips.map(tip => { return tip.name })
        
        for (let i = 0; i < this.defaultTips.length; i++) {
          let item = this.defaultTips[i]
          if (!carTips.includes(item)) {
            let rules = !['', '""', undefined, null, 'undefined'].includes(JSON.stringify(this.input_car_tips))
            this.input_car_tips = rules ? this.input_car_tips + '，' + item : item
          }
          this.car_tips.forEach(it => {
            if (item == it.name) {
              it.isSelect = true
            } 
          })
        }
        
        this.defaultTips = []
      }
    },
    // 删除
    removeTips (index, id) {
      temp_msg_keywordsc_del({id: id})
        .then(res => {
          if (res.code === 0) {
            this.car_tips.splice(index, 1)
            this.getList()
          }
        })
    },
    
    // 添加
    confirmAdd () {
      let str = this.add_car_tips.replace(/\s+/g, '').trim()
      if (str !== '') {
        let obj = {
          name: this.add_car_tips,
          type: this.type
        }
        temp_msg_keywordsc_save(obj)
          .then(res => {
            if (res.code === 0) {
              this.getList()
              this.add_car_tips = ''
            }
          })
      }
    },
    
    // 选择
    selectTips (index) {
      this.car_tips[index].isSelect = !this.car_tips[index].isSelect
      let carTips = this.car_tips.map(item => {if (item.isSelect) return item.name})
      console.log(carTips)
      this.$emit('tipsChange', { carTips, input: this.input_car_tips })
    },
  }
}
</script>

<style lang="scss" scoped>
.tips_div {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  .tips_item {
    position: relative;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    margin: 0 5px 5px 0;
    display: inline-block;
    word-spacing: nowrap;
    line-height: 40px;
    height: 40px;
    font-size: inherit;
    padding: 0 15px;
    cursor: pointer;
    &:active {
      background-color: #f9f9f9;
    }
    
    .delete-image {
      position: absolute;
      right: -5px;
      top: -16px;
      i {
        color: #000;
        font-size: 22px;
        cursor: pointer;
      }
    }
  }
  
  .tips_item_active {
    color: #409EFF;
    border-color: #409EFF;
  }
}

.all_center {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
</style>