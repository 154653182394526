export default {
  bind: function() {
    // eslint-disable-next-line no-unused-vars
    const [el, ...others] = arguments
    el.onmousemove = function() {
      el.style.display = 'inline-block'
      el.style.transition = 'all 1s'
      el.style.transform = 'rotateZ(360deg)'
    }
    el.onmouseleave = function() {
      el.style.transform = 'rotateZ(0deg)'
    }
  }
}
