<template>
	<div>
		<v-head></v-head>
		<v-sidebar></v-sidebar>
		<!--main-->
		<div class="main-box" :class="{ 'content-collapse': collapse }">
			<v-tags></v-tags>
			<div class="main-body">
				<transition name="move" mode="out-in">
					<div class="app-main">
            <div class="main-view">
              <router-view />
            </div>
					</div>
				</transition>
			</div>
		</div>

	</div>
</template>

<script>
import vHead from "./Header.vue";
import vSidebar from "./Sidebar.vue";
import vTags from "./Tags.vue";
import bus from "./bus";
export default {
	name: "admin",
	data: function () {
		return {
			collapse: ''
		}
	},
	created() {
		bus.$on("collapse", msg => {
			this.collapse = msg;
		});
	},
	methods: {},
	components: {
		vHead,
		vSidebar,
		vTags
	},
}
</script>

<style lang="scss" scoped>
.main-box {
	position: absolute;
	top: 80px;
	left: 200px;
	right: 0;
	bottom: 0;
	background: #F0F2F5;

	&.content-collapse {
		left: 65px;
	}

	.main-body {
		width: 100%;
		height: calc(100% - 40px);
		background: linear-gradient(180deg, #DDEDFC 0%, #D2DCF5 100%);
		padding: 10px;
		box-sizing: border-box;
		overflow: auto;

		.app-main {
			width: 100%;
			height: 100%;
			background-color: #ffffff;
			border-radius: 10px;
			overflow: hidden;
      padding: 10px 0 10px 10px;
      .main-view {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
      }
		}
	}
}
::-webkit-scrollbar-track-piece {
  background-color: #fff;
  -webkit-border-radius: 0
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px
}
::-webkit-scrollbar-thumb {
  // height: 50px;
  background-color: #b8b8b8;
  -webkit-border-radius: 6px;
  outline: 2px solid #fff;
  outline-offset: -2px;
  border: 2px solid #fff;
  filter: alpha(opacity = 50);
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5
}
::-webkit-scrollbar-thumb:hover {
  // height: 50px;
  background-color: #878987;
  -webkit-border-radius: 6px
}
</style>
