import router from './router'
import store from './store'
// import { Notification  } from 'element-ui'
import { getToken } from '@/utils/auth'
import { get_my_permit_api } from '@/api/system/menu'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false })

const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (getToken()) {
    if (to.path === '/login') {
      // console.log(111);
      next({ path: '/' })
      NProgress.done()
    } else {
      console.log(222);
      store.dispatch('GenerateRoutes')
      console.log('333333333', store.getters.roles.length);
      if (!store.getters.roles.length) {
        // const res = await get_my_permit_api()
        // console.log(res.data,);
        // store.dispatch('GenerateRoutes', res.data)
      }
      next()
    }
  } else {
    // console.log(333);
    console.log(to.path);
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
    // console.log(444);
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
})




