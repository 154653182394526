<template>
  <div
    :style="{
      margin: '0 auto',
      width: `${width}px`,
      height: `${height}px`}"
  >
    <template v-if="imageArr.length">
      <div class="image-box">
        <el-image
          v-if="imageArr[0]"
          :style="imageStyle"
          :src="imageArr[0]"
          :preview-src-list="imageArr"
        />
        <div class="image-count">共{{ imageArr.length || 0 }}张</div>
      </div>
    </template>
    <template v-else>
      <div
        :style="{
          margin: '0 auto',
          width: `${width}px`,
          height: `${height}px`,
          lineHeight: `${height}px`,
          textAlign: 'center',
          color: textColor,
          border: imageBorder,
          fontSize: textFontSize,
          borderRadius: '5px'
        }"
      >
        暂无图片
      </div>
    </template>
  </div>
</template>
<script>
/**
 * @name 图片预览组件
 * @intro 该组件可以通过点击图片查看放大图片，没有图片可以显示图片缺省图
 */
export default {
  name: 'PreviewImage2',
  props: {
    // 图片
    imageArr: {
      type: Array,
      default: () => []
    },
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: [Number, String],
      default: 100
    },
    imageStyle: {
      type: Object,
      default: () => {
        return {
          width: '100px',
          height: '100px',
          display: 'block'
        }
      }
    },
    imageBorder: {
      type: String,
      default: '3px solid #f1f1f1'
    },
    imageBoxShadow: {
      type: String,
      default: '0 0 4px rgba(0,0,0,.2)'
    },
    textColor: {
      type: String,
      default: 'rgba(0,0,0,.1)'
    },
    textFontSize: {
      type: String,
      default: '12px'
    }
  },
  data() {
    return {}
  },
  methods: {
    errorImg(e) {
      console.log(e)
    }
  }
}
</script>
<style lang="scss" scoped>
.image-box{
  margin: 0 auto;
  width: 100px;
  min-height: 100px;
  max-height: 100px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  background-color: #f1f1f1;
  .image-count{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20px;
    line-height: 20px;
    text-align: center;
    background-color: rgba(0,0,0,.5);
    color: #f9f9f9;
    font-size: 12px;
  }
}
.el-image-viewer__close{
  color: #fff;
}
::v-deep .el-image-viewer__close{
  color: #fff;
}
</style>
